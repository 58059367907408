import React from "react"

import { CustomHowWeDoIt } from "../components/how-we-do-it"
import Layout from "../components/layout"
import { PurpleHeroSection } from "../components/purple-hero-sections"
import SEO from "../components/seo"
import { OtherServices } from "./product-strategy"

const HeroSection = () => (
  <PurpleHeroSection
    bgpurple={false}
    titleFirst={"UI "}
    titleSecond={"/ UX"}
    subtitle={"Custom software prototyping"}
    content={"Design an engaging product that is easy-to-use"}
    image={require("../assets/UIUX-main.svg")}
  />
)
const Benefits = () => (
  <section className="white-dots-bg bg-cover bg-bottom bg-no-repeat">
    <div className="container px-5 md:px-12 pt-20 pb-32 items-start grid grid-cols-1 md:grid-cols-12 gap-10">
      <div className="md:col-span-5  bg-pred text-white  shadow-lg mr-5 px-12 py-10 pb-24 ">
        <h4 className="font-black text-xl">
          Benefits of Software Project Prototyping
        </h4>
      </div>
      <div className="md:col-span-7 text-lg text-pgray">
        <p>
          Even once your team and ours have worked together to outline the key
          goals and features of the new software system, it can be difficult for
          your team to visualize the system come to life — that’s not because
          you haven’t successfully defined what you need, but because your
          expertise is your business, not its software.
        </p>
        <p>
          So, to mitigate the risk of revealing a finished product only to have
          you discover it isn’t what you wanted, we use prototyping as a gentle
          step from the realm of abstract requirements into a more concrete
          picture of how your software will look, feel, and work. The process is
          like dipping a toe in the water before diving in.
        </p>
      </div>
    </div>
  </section>
)
const HowWeDoIt = () => {
  const data = [
    {
      title: "Design Brief",
      content:
        "The design brief starts with a product scope - either created by us or provided by you - with all the user stories, UX personas or list of features and stakeholder analysis.",
    },
    {
      title: "Wireframes",
      content:
        "We start by creating wireframes from the brief provided.This allows us to see through the customer journey.",
    },
    {
      title: "Key Screens",
      content:
        "We start by creating the most important screens for your product.This allows you to see exactly how your product will look and then approve the design.",
    },
    {
      title: "All Screens",
      content:
        "Once you are happy and approve the Key Screens we extend the design to the remaining screens and deliver the full product UX/ UI.",
    },
  ]
  return <CustomHowWeDoIt data={data} />
}
const UIUXPage = () => (
  <Layout insightCategory="user-experience">
    <SEO
      title="UX & UI Web Design Company | Best User Experience Firm | Touchcore Technology"
      description="We design solutions that are impactful. We create memorable digital experiences with stunning design and optimized user flows. User centric, conversion focused."
    />
    <HeroSection />
    <Benefits />
    <HowWeDoIt />
    <OtherServices />
  </Layout>
)
export default UIUXPage
